import React from "react";
// import { useStaticQuery, graphql } from "gatsby";
import _ from "lodash";

import { Layout } from "../components";
import processDate from "../utils/processDate";

const PressReleaseTemplate = ({ pageContext }) => {
  const {
    // title, titleWithSubscripts, image,
    subtitle,
    location,
    date,
    bodyText,
  } = pageContext;

  // const { contentfulSiteData } = useStaticQuery(
  //   graphql`
  //     query {
  //       contentfulSiteData {
  //         pressReleaseFooter {
  //           childMarkdownRemark {
  //             html
  //           }
  //         }
  //       }
  //     }
  //   `
  // );

  return (
    <Layout className="templated-page" pageData={pageContext} pressRelease>
      <h2
        className="h3 subtitle-text"
        dangerouslySetInnerHTML={{
          __html: _.get(subtitle, "childMarkdownRemark.html"),
        }}
      />
      <div className="h4">
        {location} - {processDate(date)}
      </div>
      <section
        className="body-text"
        dangerouslySetInnerHTML={{
          __html: _.get(bodyText, "childMarkdownRemark.html"),
        }}
      />
      {/*}<section
        className="footer-text"
        dangerouslySetInnerHTML={{
          __html: _.get(
            contentfulSiteData,
            "pressReleaseFooter.childMarkdownRemark.html"
          ),
        }}
      />*/}
    </Layout>
  );
};

export default PressReleaseTemplate;
